import React, { useContext, useState, useEffect, memo } from 'react';
import { Link, withRouter } from 'react-router-dom';
import Posts from '../first-page/posts';
import DownloadApp from '../first-page/download';
import Footer from '../first-page/footer';
import Navigation from '../first-page/navigation';
import { Context } from '../../Provider';
import Loading from '../tools/button/Loading';

const Post = (props) => {
  const [state, setState] = useState({
    post: false,
    posts: false
  })
  const { post, posts } = state;

  useEffect(() => {
    if (!post) getPost();
  }, []);

  useEffect(() => {
    if (post) getPosts()
  }, [post])

  const context = useContext(Context);
  const { url, request } = context;

  const getPost = () => {
    request({ path: `getnews/${props.match.params.id}` }).then((res) => {
      const newPost = res.data.data
      setState({ ...state, post: newPost })
    }).catch((err) => {
      console.log(err);
    });
  };

  const getPosts = () => {
    request({ path: 'getnews' }).then((res) => {
      const newPosts = res.data.data
      setState({ ...state, posts: newPosts })
    }).catch((err) => {
      console.log(err);
    });
  };

  if (posts) return (
    <section className="post">
      <Navigation darkTextColor />
      <div className="inner">
        <div className="content">
          <h1 className="title">
            {post.title}
          </h1>

          <img src={`${url}/newstyle/images/blog/${post.img}`} className="thumbnail" alt={post.title} />

          <p className="body">
            {post.message}
          </p>

        </div>

        <div className="sidebar">
          <p className="title">Related posts</p>
          <ul className="ul">
            {posts?.map?.(p => (
              <li key={`relative-post-${p.id}`} className="li">
                <a href={`/post/${p.id}/${p.title?.toLowerCase()?.replace(/ /g, '-')}`} >{p.title}</a>
              </li>
            ))}
          </ul>
        </div>
      </div>

      {posts && !!posts.length && (
        <div className="related">
          <p className="title">Additional Resources</p>
          <Posts posts={posts} />
        </div>
      )}


      <div className="download-app">
        <DownloadApp />
      </div>
      <Footer />
    </section>
  );

  return <Loading />

};

export default withRouter(memo(Post));
