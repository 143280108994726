import React from 'react';
import { FilledButton } from '../../tools/button';

const Developers = (props) => (
  <section id="api" className="developers">

    <div className="inner">
      <div className="img-container">
        <img src="/assets/images/developers.png" className="img" />
      </div>

      <div className="contents">
        <p className="title">You are also a part of NeuronCash system!</p>
        <p className="description">
          We have considered all the needs of developers in NeuronCash. Hence, developers will be able to develop their software systems witha collection of APIs inside the NeuronCash based on its online payment.
        </p>
        <div className="btns">
          <FilledButton value="Download PDF" download link="https://neuroncash.com/neuroncash-api.pdf" color="primary" size="l" />
        </div>
      </div>


    </div>

  </section>
);

export default Developers;
