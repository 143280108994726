import React from 'react';
import { Link } from 'react-router-dom';

const Download = (props) => (
  <section id="application" className="download">

    <div className="inner">
      <div className="contents">
        <p className="title"> The easy way to transfer money is right here!</p>
        <p className="description">
          Use the NeuronCash app to send money to friends and relatives, pay online, or make quick and easy international payments to merchants.
        </p>
        <div className="btns">
          <img src="/assets/images/app-store.png" alt="Download neuroncash ios application from app store" />
          <img src="/assets/images/play-store.png" alt="Download neuroncash android application from app store" />
        </div>
      </div>

      <div className="img-container">
        <img src="/assets/images/application.png" className="img" alt="Neuroncash appliccation" />
      </div>
    </div>

  </section>
);

export default Download;
