import React from 'react';

const ITEMS = [
  {
    title: 'Instant Account Number',
    description: 'Your account numbers are created immediately after registration and they can be used in transactions and money transfers.',
    image: '/assets/images/feature01.png'
  },
  {
    title: 'Money Withdrawal',
    description: 'You can easily control your income. Neuron Cash has widespread coverage all over the world.',
    image: '/assets/images/feature02.png'
  },
  {
    title: 'List of Friends',
    description: 'This service is created for public use. You can add your colleagues to your contact list so as to transfer money with just one click.',
    image: '/assets/images/feature03.png'
  },
  {
    title: 'Security',
    description: 'Although it is easy to use Neuron Cash, but there has been a special attention to the security of its accounts',
    image: '/assets/images/feature04.png'
  },
  {
    title: 'Real Support',
    description: 'All users of Neuron Cash can use the 24-hour support of experienced professionals in the field.',
    image: '/assets/images/feature05.png'
  },
];

const Features = () => (
  <section id='features' className="features">

    <div className="inner">
      <p className="title">Enter the new world of cryptocurrencies!</p>
      <div className='line tiny'></div>
      <p className="description">
        <strong>NeuronCash</strong> is a leading financial service allowing the users to make instant
        payments and to make money transfers securely throughout the Internet opening
        unique opportunities to Internet users and owners of the Internet businesses.
        NeuronCash targets to bring the transactions on the Internet to the ideal level!
      </p>
      <div className='items'>
        {ITEMS?.map(f => (
          <div className='item'>
            <img src={f.image} alt={f.title} />
            <p className='title'>{f.title}</p>
            <p className='description'>{f.description}</p>
          </div>
        ))}
      </div>

    </div>

  </section>
);

export default Features;
