import React, { useState, useEffect } from 'react';
import { FilledButton, EmptyButton } from '../../tools/button';

const Navigation = (props) => {
  const [state, setState] = useState({
    isHeaderActive: false
  });

  const { isHeaderActive } = state;
  const { darkTextColor } = props;

  const scrollHandler = (event) => {
    if (window.scrollY > 100) {
      setState({ ...state, isHeaderActive: true });
    } else {
      setState({ ...state, isHeaderActive: false });
    }
  };
  useEffect(() => {
    window.addEventListener('scroll', scrollHandler, true);
    return () => {
      window.removeEventListener('scroll', scrollHandler, true);
    };
  }, []);

  return (
    <nav className={`navigation ${darkTextColor && 'darkTextColor'} ${isHeaderActive && 'active'}`}>
      <a href="/">
        <img src="/assets/images/logo-white.png" className="logo" alt="neuroncash logo" />
      </a>
      <ul>
        <li><a href="/#">Home</a></li>
        <li><a href="/#features">Features</a></li>
        <li><a href="/#application">Application</a></li>
        <li><a href="/#api">API</a></li>
        <li><a href="/contact-us">Contact us</a></li>
      </ul>
      <div id="navigation-before-login" className={`btns ${isHeaderActive ? 'active' : 'passive'}`}>
        <FilledButton id="navigation-signup" value="Signup" href="/register" color="primary" size="l" />
        <EmptyButton id="navigation-login" value="Login" href="/login" color="white" size="l" />
      </div>
      <div id="navigation-after-login" className={`btns ${isHeaderActive ? 'active' : 'passive'}`}>
        <EmptyButton id="navigation-logout" value="Logout" href="/logout2" color="ghost" size="l" />
        <FilledButton id="navigation-panel" value="Panel" href="/client" color="blue" size="l" />
      </div>
    </nav>
  );
};

export default Navigation;
