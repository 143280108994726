import React, { useState, useEffect, useContext } from 'react';
import { MdPhone, MdEmail, MdLocationOn } from 'react-icons/md';
import TextareaAutosize from 'react-textarea-autosize';
import { ToastContainer, toast } from 'react-toastify';
import { FilledButton } from '../tools/button';
import { Context } from '../../Provider';
import 'react-toastify/dist/ReactToastify.css';
import Navigation from '../first-page/navigation';

toast.configure({
  autoClose: 3000,
  draggable: false,
});


const ContactUs = () => {
  const [state, setState] = useState({
    fullname: '',
    email: '',
    text: '',
    isVisibleLoading: false
  });

  const context = useContext(Context);
  const { request } = context;

  const {
    fullname, email, text, isVisibleLoading
  } = state;

  const setValue = (e) => {
    const { name, value } = e.target;
    const obj = {};
    obj[name] = value;
    setState({ ...state, ...obj });
  };

  const handleLoaded = (_) => {
    window.grecaptcha.ready((_) => {
      window.grecaptcha
        .execute('_reCAPTCHA_site_key_', { action: 'homepage' })
        .then((token) => {
          // ...
        });
    });
  };

  useEffect(() => {
    // Add reCaptcha
    const script = document.createElement('script');
    script.src = 'https://www.google.com/recaptcha/api.js?render=_reCAPTCHA_site_key';
    script.addEventListener('load', handleLoaded);
    document.body.appendChild(script);
  }, []);


  const onSubmit = () => {
    if (!isVisibleLoading) {
      const config = {
        position: toast.POSITION.TOP_LEFT,
        autoClose: 8000
      };

      if (fullname && email && text) {
        setState({ ...state, isVisibleLoading: true });
        request({
          method: 'post',
          path: 'getmessage',
          data: {
            fullname, email, text
          }
        }).then((res) => {
          toast.success('Your message has been successfully sent.', config);
          setState({
            ...state, text: '', fullname: '', email: '', isVisibleLoading: false
          });
        }).catch((err) => {
          toast.error('An unexpected problem occurred.', config);
          setState({
            ...state, isVisibleLoading: false
          });
        });
      } else {
        toast.error('Please enter the required fields.', config);
        setState({
          ...state, isVisibleLoading: false
        });
      }
    }
  };


  return (
    <div className="contact-us-wrapper">
      <Navigation darkTextColor />
      <div className="thumbnail">
        <img src="/assets/images/contact-us.jpg" alt="Neuroncash contact us" />
      </div>
      <div id="contact-us" className="contact-us">
        <ToastContainer />

        <div className="box info">
          <p className="title">Contact NeuronCash</p>

          <div className="item">
            <MdPhone className="icon" />
            <div className="inner">
              <p className="label">Phone</p>
              <p className="value">+902429991952</p>
            </div>
          </div>

          <div className="item">
            <MdEmail className="icon" />
            <div className="inner">
              <p className="label">Email</p>
              <p className="value">support@neuroncash.com</p>
            </div>
          </div>

          <div className="item">
            <MdLocationOn className="icon location" />
            <div className="inner">
              <p className="label">Address</p>
              <p className="value">FENER Mah MERKEZ FENER Mah 1971 Sk. D-BLOCK NO:13 MURATPASA ANTALYA, Serbest Olmayan Tuketici</p>
            </div>
          </div>
        </div>

        <div className="box form">
          <input name="fullname" type="text" value={fullname || ''} placeholder="Your name" onChange={setValue} />
          <input name="email" type="email" value={email || ''} placeholder="Your email address" onChange={setValue} />
          <TextareaAutosize name="text" className="textarea" minRows={3} value={text || ''} placeholder="Your message ..." onChange={setValue} />
          <div
            className="g-recaptcha"
            data-sitekey="6Lej9_YUAAAAAJZzwU8zPEP4AMyHABYwciXqMxYR"
            data-size="invisible"
          />
          <FilledButton value={isVisibleLoading ? 'Loading ...' : 'Submit'} color="primary" size="l" onClick={onSubmit} />
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
