import React, { memo } from 'react';
import { Route } from 'react-router-dom';
import FirstPage from './first-page';
import Post from './blog/Post';
import ContactUs from './contact-us';

const App = () => (
  <>
    <Route exact path="/" component={FirstPage} />
    <Route exact path="/contact-us" component={ContactUs} />
    <Route exact path="/post/:id/:title" component={Post} />
    <Route
      path="/register"
      component={() => {
        global.window && (global.window.location.href = 'https://neuroncash.com/register');
        return null;
      }}
    />
    <Route
      path="/login"
      component={() => {
        global.window && (global.window.location.href = 'https://neuroncash.com/login');
        return null;
      }}
    />
    <Route
      path="/logout2"
      component={() => {
        global.window && (global.window.location.href = 'https://neuroncash.com/logout2');
        return null;
      }}
    />
    <Route
      path="/client"
      component={() => {
        global.window && (global.window.location.href = 'https://neuroncash.com/client');
        return null;
      }}
    />
  </>
);

export default memo(App);
