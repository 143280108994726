import React from 'react';
import { Link } from 'react-router-dom';

const Footer = (props) => (
  <section style={props.style} className="footer">

    {/* <div className="inner">
      <div className="col">
        <p className="title">Title One</p>
        <ul className="ul">
          <li className="li"><a className="a">Sample item 1 from api</a></li>
          <li className="li"><a className="a">Sample item 2 from api</a></li>
          <li className="li"><a className="a">Sample item 3 from api</a></li>
          <li className="li"><a className="a">Sample item 4 from api</a></li>
        </ul>
      </div>

      <div className="col">
        <p className="title">Title Two</p>
        <ul className="ul">
          <li className="li"><a className="a">Sample item 1 from api</a></li>
          <li className="li"><a className="a">Sample item 2 from api</a></li>
          <li className="li"><a className="a">Sample item 3 from api</a></li>
          <li className="li"><a className="a">Sample item 4 from api</a></li>
        </ul>
      </div>

      <div className="col">
        <p className="title">Title Three</p>
        <ul className="ul">
          <li className="li"><a className="a">Sample item 1 from api</a></li>
          <li className="li"><a className="a">Sample item 2 from api</a></li>
          <li className="li"><a className="a">Sample item 3 from api</a></li>
          <li className="li"><a className="a">Sample item 4 from api</a></li>
        </ul>
      </div>
    </div> */}

    <div className="copyright">
      <p className="p">Copyright © 2020 Neuroncash, Co.</p>
    </div>

  </section>
);

export default Footer;
