import React, { useState, createContext } from 'react';
import { BrowserRouter as Router, Switch } from 'react-router-dom';
import App from './ui';
import ScrollToTop from './ScrollToTop';

const axios = require('axios');

const Context = createContext();

const Provider = (props) => {
  const [state, setState] = useState({
    apiUrl: 'https://neuroncash.com/api/',
    url: 'https://neuroncash.com',
    message: '',
    isVisibleRequestSpinner: false,
  });

  const { apiUrl, message, isVisibleRequestSpinner } = state;

  const setMessage = (newMessage) => {
    setState({ ...state, message: newMessage });
    setTimeout(() => {
      clearMessage();
    }, 2000);
  };

  const request = async (obj) => {
    const { method, path, data } = obj;
    const axiosConfig = {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    };
    setState({ ...state, isVisibleRequestSpinner: true });

    let result;

    if (method === 'post') {
      result = await axios.post(apiUrl + path, JSON.stringify(data), axiosConfig);
    } else {
      result = await axios.get(apiUrl + path, axiosConfig);
    }

    setState({ ...state, isVisibleRequestSpinner: false });

    return result || null;
  };

  const clearMessage = () => {
    setState({ ...state, message: false });
  };

  return (
    <Context.Provider value={{
      ...state,
      request,
      setMessage,
    }}
    >
      <Router>
        <ScrollToTop>
          <Switch>
            <App />
          </Switch>
        </ScrollToTop>
      </Router>
    </Context.Provider>
  );
};

export { Context };
export default Provider;
