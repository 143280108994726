import React, { Component } from 'react';
import {
  Link, DirectLink, Element, Events, animateScroll as scroll, scrollSpy, scroller
} from 'react-scroll';
import Navigation from './navigation';
import Intro from './intro';
import Features from './features';
import Download from './download';
import Posts from './posts';
import Brands from './brands';
import Developers from './developers';
import Footer from './footer';
import Cards from './cards';
import MasterCard from './mastercard';

class FirstPage extends Component {
  componentDidMount = () => {
    Events.scrollEvent.register('begin', function () {
      // console.log('begin', arguments);
    });

    Events.scrollEvent.register('end', function () {
      // console.log('end', arguments);
    });
  }

  componentWillUnmount = () => {
    Events.scrollEvent.remove('begin');
    Events.scrollEvent.remove('end');
  }

  scrollTo = () => {
    scroller.scrollTo('scroll-to-element', {
      duration: 800,
      delay: 0,
      smooth: 'easeInOutQuart'
    });
  }

  render() {
    return (
      <div className="first-page">
        {/* Navigation */}
        <Navigation scrollTo={this.scrollTo} />

        {/* Intro */}
        <Intro />

        {/* Features */}
        <Features />

        {/* Download application */}
        <Download />

        {/* Cards */}
        <Cards />

        {/* Our partners */}
        {/* <Brands /> */}

        {/* MasterCard */}
        <MasterCard />

        {/* Latest Posts */}
        <Posts />

        {/* API for Developers */}
        <Developers />

        {/* Footer */}
        <Footer />

      </div>
    );
  }
}

export default FirstPage;
