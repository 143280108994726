import React from 'react';
import { Link } from 'react-router-dom';

const shortid = require('shortid');

const Filled = (props) => {
  const {
    id, link, href, value, color, size, onClick, download
  } = props;
  if (download || href) {
    return (
      <a id={id || shortid.generate()} href={link || href} download={download || false} className={`btn btn--type-filled btn--color-${color} btn--size-${size}`}>
        <p>{value}</p>
      </a>
    );
  }
  if (link) {
    return (
      <Link id={id || shortid.generate()} to={link || href} className={`btn btn--type-filled btn--color-${color} btn--size-${size}`}>
        <p>{value}</p>
      </Link>
    );
  }

  return (
    <div id={id || shortid.generate()} onClick={onClick} className={`btn btn--type-filled btn--color-${color} btn--size-${size}`}>
      <p>{value}</p>
    </div>
  );
};
export default Filled;
