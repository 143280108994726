import React from 'react';

const CARDS = [
  {
    number: '283746823746', amount: 20, color1: '#812b86', color2: '#3d0d4b'
  },
  {
    number: '154746823798', amount: 50, color1: '#0477bd', color2: '#29235c'
  },
  {
    number: '763746823723', amount: 100, color1: '#fb6976', color2: '#940411'
  },
];

const Cards = (props) => (
  <section className="cards">
    <h2 className="title">A gift from NeuronCash</h2>
    <div className="line tiny" />
    <div className="items">
      {CARDS?.map(c => (
        <div style={{
          background: `radial-gradient(
                        circle,
                        ${c.color1} 0%,
                        ${c.color2} 63%,
                        ${c.color2} 100%
                      )`
        }} className="card">
          <div className="inner">
            <div className="details row">
              <p className="number">{c.number}</p>
              <p className="amount">
                {c.amount}
                <span>$</span>
              </p>
            </div>

            <div className="logo-container">
              <img
                src="/assets/images/card-logo.png"
                className="logo"
                alt="Neuroncash gift card"
              />
            </div>

            <div className="info row">
              <p className="message">A gift from NeuronCash</p>
              <div className="brand">
                <p className="name">Neuron</p>
                <p className="subtitle">Cash</p>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  </section>
);

export default Cards;
