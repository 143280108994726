import React from 'react';
import { Link } from 'react-router-dom';

const shortid = require('shortid');

const Empty = (props) => {
  const {
    id, link, download, href, value, color, size
  } = props;

  if (download || href) {
    return (
      <a id={id || shortid.generate()} href={link || href} download={download || false} className={`btn btn--type-empty btn--color-${color} btn--size-${size}`}>
        <p>{value}</p>
      </a>
    );
  }
  return (
    <Link id={id || shortid.generate()} to={link || href} className={`btn btn--type-empty btn--color-${color} btn--size-${size}`}>
      <p>{value}</p>
    </Link>
  );
};
export default Empty;
