import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Context } from '../../../Provider';

const Posts = (props) => {
  const [state, setState] = useState({
    posts: props.posts || null
  })
  const { posts } = state;

  useEffect(() => {
    if (!props.posts) getPosts();
  }, []);

  const context = useContext(Context);
  const { request, url } = context;

  const getPosts = () => {
    request({ path: 'getnews' }).then((res) => {
      const newPosts = res?.data?.data
      setState({ ...state, posts: newPosts })
    }).catch((err) => {
      console.log(err);
    });
  };

  return (
    <section className="big-posts">
      {posts?.slice(0, 2)?.map(p => (
        <a key={`post${p.id}`} href={`/post/${p.id}/${p.title?.toLowerCase()?.replace(/ /g, '-')}`} className="big-post" style={{
          backgroundImage: `url(${url}/newstyle/images/blog/${p.img})`,
          backgroundSize: '110% auto',
          backgroundPosition: 'center'
        }}>
          <div className="inner">
            <h2 className="title">{p.title}</h2>
            <p className="description">{p.message}</p>
          </div>
        </a>
      ))}
    </section>
  );
};

export default Posts;
