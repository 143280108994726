import React from 'react';
import { FilledButton, EmptyButton } from '../../tools/button';

const Intro = () => (
  <section className="intro">

    {/* Background Gradient */}
    <div className="cover">
      {/* Body */}
      <div className="body">
        <img className="logo" src="/assets/images/logo2-gradient.png" alt="Neuron cash logo" />
        <h1 className="title">Your best moves now, easy and secure!</h1>
        <p className="description">
          We have provided a good section for you to do your transfers easy and fast.
        </p>

        <div id="intro-before-login" className="btns">
          <FilledButton id="intro-signup" value="Signup" href="/register" color="primary" size="l" />
          <EmptyButton id="intro-login" value="Login" href="/login" color="white" size="l" />
        </div>
        <div id="intro-after-login" className="btns">
          <EmptyButton id="intro-logout" value="Logout" href="/logout2" color="ghost" size="l" />
          <FilledButton id="intro-panel" value="Panel" href="/client" color="blue" size="l" />
        </div>

      </div>
    </div>

  </section>
);

export default Intro;
