import React from 'react';

const MasterCard = (props) => (
  <section id="mastercard" className="mastercard">

    <div className="inner">
      <div className="contents">
        <p className="title">Experience the power with us, coming soon!</p>
        {/* <p className="description">
        </p> */}
      </div>

      <div className="img-container">
        <img src="/assets/images/mastercard.png" className="img" alt="Neuroncash appliccation" />
      </div>
    </div>

  </section>
);

export default MasterCard;
